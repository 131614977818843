<template>
	<v-card>
		<s-toolbar
			label="Agregar Jabas Dañadas"
			close
			@close="closeDialogDamageJaba()"
			dark
			color="#BAB6B5"
			add
			@add="addDamageJaba()"
		></s-toolbar>
		<v-container>	
			<v-row>
				<v-col>

				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-select-definition
						label="Tipo de defecto"
						:def="1443"
						:dgr="itemDamage.TypeCultive == 1 ? 56 : 57"
						v-model="pallet.TypeDamage"
					></s-select-definition>
				</v-col>
				<v-col>
					<s-text 
						label="Peso Kg."
						decimal
						v-model="pallet.DmgDamageWeight"
					></s-text>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headersDamageJaba"
						:items="palletsDamage"
						key="DmgID"
					>
						<template v-slot:item.accion="{item}">
							<v-btn
                                x-small
                                :color="'error'"
                                fab
                                style="margin: 6px 6px 6px 6px;"
								@click="deleteItemDamage(item)"
							>
								<i class="fas fa-trash"></i>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>


<script>
	import _sDamageJabaService from "@/services/FrozenProduction/FrzDamageJabaService";

	export default {
        props:{
            itemDamage: {
                default: null,
                type: Object,
            }
        },
		data() {
			return {
				pallet: {},
				palletsDamage: [],
				headersDamageJaba: [
					{text: "ID", value: "DmgID"},
					{text: "Daño", value: "TypeDamageName"},
					{text: "Peso Kg.", value: "DmgDamageWeight"},
					{text: "Acción", value: "accion", align: "center"},
				],
				// manual: false,
				// filter: {
					
				// },
				lotsReceptionFrozen: [],
				infoReception: "",
				lotObj: {},
                RcfID: 0,
				LlpID: 0,
			}
		},

		watch: {
			// manual() {
			// 	if(this.manual)
			// 	{
			// 		this.getLotReceptions();
			// 	}
			// },

			// "filter.DateBegin"()
			// {
			// 	if(this.manual)
			// 	{
			// 		this.getLotReceptions();
			// 	}
			// },

			// "filter.DateEnd"()
			// {
			// 	if(this.manual)
			// 	{
			// 		this.getLotReceptions();
			// 	}
			// }
            itemDamage(){      
                this.RcfID = this.itemDamage.RcfID; 
				this.LlpID = this.itemDamage.LlpID;                        
                this.getDamageJaba();
            }
		},

		methods: {


			closeDialogDamageJaba() {
                this.RcfID = 0;                
				this.$emit("closeDialogDamageJaba")
			},

			addDamageJaba()
			{
				if(this.pallet.DmgDamageWeight == "" || this.pallet.DmgDamageWeight == 0)
				{
					this.$fun.alert("Ingrese el peso en kilogramos");
					return;
				}
				// if(this.lotsReceptionFrozen.length == 0)
				// {
				// 	this.$fun.alert("No hay lotes recepcionados");
				// 	return;
				// }
                
                this.pallet.RcfID = this.RcfID;	
				this.pallet.LlpID = this.LlpID;				
				this.pallet.SecStatus = 1;
				this.pallet.UsrCreateID = this.$fun.getUserID();
				this.pallet.UsrUpdateID = this.$fun.getUserID();
				console.log(this.pallet);
				_sDamageJabaService
				.save(this.pallet, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.palletsDamage.push(this.pallet);
						this.$fun.alert("Agregado correctamente", "success");
						this.getDamageJaba();
					}
				})
			},

			// getLotReceptions()
			// {
			// 	_sDamageJabaService
			// 	.listreceptionfrozen(this.filter, this.$fun.getUserID())
			// 	.then(resp => {
			// 		if(resp.status == 200)
			// 		{
			// 			this.lotsReceptionFrozen = resp.data;
			// 			if(this.lotsReceptionFrozen.length > 0)
			// 			{
			// 				this.infoReception = this.lotsReceptionFrozen[0].RcfName;
			// 				this.lotObj = this.lotsReceptionFrozen[0];
			// 				this.getDamageJaba();
			// 			}
			// 		}
			// 	})
			// },

			changeRcfID(item)
			{
				if(item != undefined)
				{
					this.infoReception = item.RcfName;
					this.lotObj = item;
					this.getDamageJaba();
				}else{
                    this.pallet.DmgDamageWeight = "";
                }
			},

			getDamageJaba()
			{
                if(this.RcfID != undefined){
                    let obj = {
                        RcfID : this.RcfID,
						LlpID : this.LlpID
                    }

                    _sDamageJabaService
                    .list(obj, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            this.palletsDamage = resp.data;
                        }
                    })
                }
			},

			deleteItemDamage(item)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						item.SecStatus = 0;
						item.UsrUpdateID = this.$fun.getUserID();

						_sDamageJabaService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Eliminado correctamente", "success");
								this.getDamageJaba();
							}
						})
					}
				})
				
			}
		},
        mounted(){
			console.log("itemDamage", this.itemDamage)
            this.RcfID = this.itemDamage.RcfID;
			this.LlpID = this.itemDamage.LlpID;
            this.getDamageJaba();            
        },
		created () {		
            this.getDamageJaba();
		},
	}
</script>