import Service from "../Service";
const resource = "/lotproductionselectionpallet/";

export default {

    /* GetPalletSend(obj, requestID) {
        return Service.post(resource + "GetPalletSend", obj, {
            params: { requestID: requestID },
        });
    },

     */
    getlotprodsupplypallet(obj, requestID) {
        return Service.post(resource + "getlotprodsupplypallet", obj, {
            params: { requestID: requestID },
        });
    },

    getlotprodselFrozenCatPalletDed(obj, requestID) {
        return Service.post(resource + "getlotprodselFrozenCatPalletDed", obj, {
            params: {  requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    /* pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    

    send(obj, requestID) {
        return Service.post(resource + "send", obj, {
            params: { requestID: requestID },
        });
    },

    transfer(obj, requestID) {
        return Service.post(resource + "transfer", obj, {
            params: { requestID: requestID },
        });
    }, */

};