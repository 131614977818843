<template v-slot:no-data>
	<div>
		<v-container style="display: contents;">
			<v-row>
				
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col cols="2" class="s-col-form">
								<s-select-definition
									label="Cultivo"
									:def="1173"
									v-model="TypeCultiveFilter"
								/>
							</v-col> 
						</v-row>
						<v-row style="margin: auto;">
							<v-col
								cols="6"
								lg="3"
								md="4"
								:key="col.TypeFrozenCategory"
								v-for="col in FrozenCategories"
							>
								<v-card  style="display: grid; background: #eaeaea;">
									<v-card-title>
										<span class="text-h6 font-weight-light"
											>{{ col.TypeFrozenCategoryName }}
											<!-- <v-chip :color="'info'">
												{{ col.TypePalletHelper }}
											</v-chip> -->
										</span>
										<span>-&nbsp;{{ col.TypeCultiveName}}</span>
									</v-card-title>
									<v-img
										lazy-src="../../../assets/pallets44.png"
										max-height="200"
										max-width="200"
										src="../../../assets/pallets44.png"
										><v-btn
											style="border-radius: 100%; height: 44%; width: 30%; margin: auto; display: flex; justify-content: space-evenly; "
											color="warning"
											@click="openModal(col)"
										>
											<span class="white--text text-h4">{{
												col.LpsQuantityJaba
											}}</span>
										</v-btn>
									</v-img>
								</v-card>
								<v-row style="margin-top: 0.1em;">
									<v-col v-if="col.TypeFrozenCategory != 7 && col.TypeFrozenCategory != 10 " cols="12" class="s-col-form">
										<s-select-definition
											:def="1318"
											label="Tipo"
											v-model="col.TypeSelectionFrozen"
											@input="inputTypeSelectionFrozen($event)"
										/>
									</v-col>
									<v-col v-if="col.TypeFrozenCategory == 0" cols="6" class="s-col-form">
										<s-text
											v-model="col.LlpWeight"
											label="Peso"
											decimal>
										</s-text>
									</v-col>
									<v-col cols="12" class="s-col-form">
										<s-select-definition
											add
											:def="1363"
											label="Tipo de parihuela"
											v-model="col.TypePalletMate"
										/>
									</v-col>
									<!-- <v-col cols="12" class="s-col-form">
										<s-select-fruit-state
											v-model="col.FtsID"
											full
											:TypeCultive="col.TypeCultive"
											label="Estado Fruta"
										></s-select-fruit-state>
									</v-col> -->
									<!-- <v-col cols="6" class="s-col-form">
										<s-select-definition
											v-model="col.TypeDestiny"
											:def="1335"
											label="Destino"
										></s-select-definition>
									</v-col> -->
									<v-col cols="12">
										<v-btn
											block
											
											small
											elevation="3"
											color="info"
											style="width:100%"
											@click="clickSend(col)"
										>
											Crear
										</v-btn>
									</v-col>
									
								</v-row>
							</v-col>
						</v-row>
					</v-card>

					<v-card dense>
						<v-row style="margin: auto;">
							<v-col cols="12">
								<s-toolbar
									dark
									:color="'#BAB6B5'"
									class="mr-4 ml-2"
									style="display: flex"
									print
									@print="printQR()"
								></s-toolbar>
							</v-col>
						</v-row>

						<v-row justify="center">

							<v-col cols="4">
								<s-date label="Fecha Inicio"
									v-model="itemsSendDateBegin"
									@input="searchitemsSend($event)"
									>
								</s-date>
							</v-col>
							<v-col cols="4">
								<s-date label="Fecha Fin"
									v-model="itemsSendDateEnd"
									@input="searchitemsSend($event)"
									>
								</s-date>
							</v-col>
							
							
						</v-row>
					<!--	<v-row style="margin: auto;">
							<v-col cols="4">
								<s-select-definition
									:def="1230"							 
									@input="inputFilterFrozen($event)"
									label="Tipo">
								</s-select-definition>
							</v-col>
							<v-col cols="4">
								<s-select-definition
									:def="1173"							 
									@input="inputTypeSelectionFrozen($event)"
									label="Tipo">
								</s-select-definition>
							</v-col>
							
						</v-row>-->
						<v-row style="margin: auto;">
							<v-col cols="12">
								
								<v-data-table
									:items="itemsSend"
									item-key="LlpID"
									dense
									:headers="headerSend"
									:items-per-page="15"
									
									disable-sort
									v-model="selected"
									@click:row="rowClickSend"
									:search="search"
								>
								<!-- show-group-by -->
								<!-- group-by="TypeSelectionFrozenName" rowSelected($event)-->
									<template v-slot:top>
										<v-text-field
										v-model="search"
										label="Search"
										class="mx-4"
										></v-text-field>
									</template>

									<template v-slot:item.PrfYuliano="row">
										<v-chip
											small
											dark
											outlined
											:color="'red'"
										>
											{{row.item.PrfYuliano}}
										</v-chip>
									</template>
									<template v-slot:item.Tranfer="props">
										<v-tooltip top allow-overflow color="primary">
											<!-- fab border-radius: 13px; fa-exchange-alt group-by="TypeDestinyName"-->
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													x-small
													:color="'info'"
													fab
													style="margin: 10px 10px 10px 10px;"
													@click="clickTranfer(props.item)"
													v-bind="attrs"
													v-on="on"
													>
													<v-icon class="fas fa-arrow-circle-up"></v-icon>
												</v-btn>
											</template>
											<span>Transferir</span>
										</v-tooltip>
										
									</template>

									<template v-slot:item.delete="props">
												
												<v-btn
													v-if="props.item.LlpState == 1"
													x-small
													:color="'error'"
													fab
													style="margin: 6px 6px 6px 6px;"
													@click="deleteParihuela(props.item)">
													<v-icon
															style="font-size: 16px !important">
															fas fa-times</v-icon>
												</v-btn>
												
									</template>
									<template v-slot:item.damage="props">
										<v-btn v-if="props.item.TypeSelectionFrozenName == null"
											color="warning darken-1"
										 	x-small
										 	@click="ModelDamageJaba(props.item)"
										 	fab>
											<v-icon color="white">
												mdi-recycle
											</v-icon>

										</v-btn>
									</template>

									<template   v-slot:item.update="props">
										<v-btn  
										v-if="props.item.TypeFrozenCategory != 9 && props.item.TypeFrozenCategory != 10 && props.item.LlpState == 1"
										 color="light-green darken-1"
										 x-small
										 @click="ModelupdatePallet(props.item)"
										 fab>
											<v-icon color="white">
												fa-light fa-pen
											</v-icon>
										</v-btn>
									</template>

									<template v-slot:item.TypeSelectionFrozenName="row">
										{{ ((row.item.TypeSelectionFrozenName === null) ? 'DESCARTE' : row.item.TypeSelectionFrozenName) }}
									</template>

									<template v-slot:item.LlpState="row">									
										<v-chip small :color="((row.item.LlpState == 1) ? 'warning' : 'success')">
											{{ ((row.item.LlpState == 1) ? 'Abastecido' : "En Maduracion") }}
										</v-chip>
									</template>
									<template v-slot:item.SecCreate="row">									
										{{ (new Date(row.item.SecCreate).getHours()) + ":"+
											""+ (new Date(row.item.SecCreate).getMinutes())
										}}
									</template>
									<!-- <template v-slot:item.accion="props">
										<v-tooltip top allow-overflow color="primary">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													x-small
													:color="'info'"
													fab
													style="border-radius: 13px; margin: 10px 10px 10px 10px;"
													@click="printQR(props.item)"
													v-bind="attrs"
													v-on="on"
													>
													
													<v-icon class="fas fa-qrcode"></v-icon>
												</v-btn>
											</template>
											<span>Imprimir QR</span>
										</v-tooltip>	
									</template> -->

								</v-data-table>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>


		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			fullscreen
		>
			<create-selection-pallet
				:TypeCultive="TypeCultive"
				:nroPallet="PalletNro" 
				:TypeFrozenCategory="TypeFrozenCategory" 
				:TypeFrozenCategoryName ="TypeFrozenCategoryName"
				@close="close($event)"
				
			></create-selection-pallet>
			<!-- @sendData="sendData($event)" -->
		</v-dialog>


		<!-- Model para editar un pallet  -->
		<v-dialog
			v-if="openDialogEditPallet"
			width="600"
			v-model="openDialogEditPallet"
			persistent
			transition="dialog-bottom-transition">

			<s-toolbar
					label="Edición de pallet"
					dark
					save
					close
					color="primary"
					@save="editPallet(itemsEdit)"
					@close="closeEdit()">
			</s-toolbar>
			<v-card>
				<v-container>
					<v-row style="height:200px">

						<v-col cols="6">
							<s-select-definition
								:def="1318"
								v-model="itemsEdit.TypeSelectionFrozen"
								@input="inputTypeSelectionFrozen($event)"
								label="Tipo">
							</s-select-definition>
						</v-col>

						<v-col>
							<s-text 
								label="Peso Bruto"
								v-model="itemsEdit.LlpWeightOriginalGross" 
								decimal>
							</s-text>
						</v-col>

						<v-col cols="6">
							<s-select-definition
								add
								:def="1363"
								label="Tipo de parihuela"
								v-model="itemsEdit.TypePalletMate"/>
						</v-col>

					</v-row>
				</v-container>
			</v-card>

		</v-dialog>

		<v-dialog
			v-if="dialogDiscard"
			v-model="dialogDiscard"
			persistent
		>
			<v-card>
				<!-- <s-crud
					:config="c"
				></s-crud> -->
				<s-toolbar
					label="Defectos"
					close
					dark
					color="#BAB6B5"
					add
					@close="closeModalDialogDiscard()"
					@add="addDefectDiscard()"
				></s-toolbar>
				<v-col>
					<v-row>
						<v-col>
							<s-select 
								dense 
								outlined 
								label="Defectos"
								item-value="DfcID"
								item-text="DfcName"
								item
								:items="itemsDefects"
								v-model="defect.DfcID"
								
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table
								:headers="headerDiscard"
								:items="itemsDiscard"
								item-key="LpdID"
								
							>
								
								<template v-slot:item.accion="{ item }">
									<v-btn block color="error" @click="removeDefectDiscard(item)" x-small>
									<v-icon>mdi-close</v-icon></v-btn
									>
								</template>

								<!-- <template v-slot:item.LlpWeight="{ item }">
									{{item.LlpWeight.toFixed(2)}}
								</template>

								<template v-slot:item.LlpWeightOriginalNet="{ item }">
									{{item.LlpWeightOriginalNet.toFixed(2)}}
								</template> -->
		

							</v-data-table>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>

		<v-dialog 
			v-model="openDialogDamageJaba"
			width="800">
			<damage-java :itemDamage="itemPalletDamage" @closeDialogDamageJaba="closeDialogDamageJaba()"></damage-java>
		</v-dialog>


		<v-card>
			<v-row justify="center" cols="12" style="display: none">
				<v-col cols="12" v-for="item in selected">
					
					<qr-code
						:text="
							item.LlpID +
								',' +
								item.LlpQuantityJaba +
								',' +
								item.TypeFrozenCategoryName  +
								',' +
								((item.TypeSelectionFrozenName == null ) ? 'DESCARTE' : item.TypeSelectionFrozenName ) +
								',' +
								item.TypeCropName +
								',' +
								item.TypeCultiveName +
								',' +
								item.VrtName +
								',' +
								item.LlpWeightOriginalNet
						"
						error-level="H"
						:size="360"
						hidden
					>
					</qr-code>
					<br />
				</v-col>
			</v-row>
		</v-card>

	</div>
</template>
<script>

	import SSelectFruitState from '../../../components/FrozenProduction/FruitState/SSelectFruitState.vue';
	//Service
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdSelPalletSendService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletSendService"
	import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletService"
	import _sDamageFlawCultiveService from '@/services/FrozenProduction/DamageFlawCultiveService';	
	import _sLotProdSelPalletDefectService from "../../../services/FrozenProduction/LotProductionSelectionPalletDefectService.js"
	
	import DamageJava from './FrzSupplyPaletDamageJava.vue';
	import CreateSelectionPallet from './FrzSupplyPalletNew.vue';
	import FrzTransferPallet from './FrzTransferPallet.vue';
	import qrCode from "vue-qr-generator";
	import signalr from "signalr";
	

	export default {
		components: { CreateSelectionPallet, SSelectFruitState, qrCode, FrzTransferPallet , DamageJava},
		data: () => ({
			TypeCultiveFilter: "",
			search: "",
			itemsSendDateBegin: "",
			itemsSendDateEnd: "",
			headerDiscard:[
				{text: 'Accion', value: 'accion'},
				{text: 'ID', value: 'LpdID'},
				{text: 'Defecto', value: 'DfcName'},
			],
			itemsDiscard: [],
			itemsDefects: [],
			weightPalletEdit : 0,
			defect: {},
			dialogDiscard: false,
			FrozenCategories: [],
			openDialog: false,
			openDialogEditPallet : false,
			openDialogDamageJaba : false,
			dataEdit: null,

			itemsEdit : {},

			PalletNro: 0,
			TypeFrozenCategory: 0,
			TypeFrozenCategoryName : "",
			textQR: "",
			itemsSend: [],
			headerSend: [
				
				{ text: "Borrar", value: "delete" },
				{ text: "Editar", value: "update" },
				{ text: "Daños", value: "damage" },
				{ text: "Correlativo", value: "LlpCorrelative" },
				{ text: "Id", value: "LlpID" },
				{ text: "Lote Producción", value: "PrfYuliano" },
				{ text: "Peso Bruto", value: "LlpWeightOriginalGross"},
				{ text: "Peso Neto", value: "LlpWeightOriginalNet" },
				/* { text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide", width: 100 }, */
				/* { text: "Peso Pallet", value: "LlpWeight"}, */
				{ text: "Tipo Selección", value: "TypeSelectionFrozenName" },
				{ text: "Categoría", value: "TypeFrozenCategoryName" },
				{ text: "Cant. Jabas", value: "LlpQuantityJaba" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "Cultivo", value: "TypeCultiveName" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Estado", value: "LlpState" },
				{ text: "Hora", value: "SecCreate" },
				
			],
			pallet: {},
			TypeCultive: 0,
			IsEdit: false,
			selected: [],
			itemsDetail : [],
			qrcodestring: "",
			TypeFrozenCategoryEdit : 0,
			LlpID: 0,
			openTranfer: false,
			itemsTranfer: {},
			executeQR: false,
			itemPalletDamage:{},
		}),
		watch: {
			TypeCultiveFilter(){
				this.intitialize();
			},
			
		},
		created() {
			this.intitialize();
			this.close();
			console.log("persmisos sam", this.$fun.getUserInfo().PrsID);
		},

		methods: {
			ModelDamageJaba(item){
				
				this.itemPalletDamage = item;
				this.openDialogDamageJaba= true;

			},
			closeDialogDamageJaba(){
				this.itemPalletDamage = {};
				this.openDialogDamageJaba= false;
			},

			getPalletSend(){
				//samir
				if(this.itemsSendDateBegin == "" || this.itemsSendDateEnd == ""){
					return;
				}
				_sLotProdSelPalletSendService
					.getlotproductionselectionpalletsend(
						{ 
							PrsID : this.$fun.getUserInfo().PrsID,
							DateBegin : this.itemsSendDateBegin,
							DateEnd : this.itemsSendDateEnd
						}
						, this.$fun.getUserID())
					.then(r => {
						this.itemsSend = r.data;

						console.log("item cargados", this.itemsSend);
						this.TypeFrozenCategoryEdit = r.data.TypeFrozenCategory

						if(this.executeQR){
							if(this.itemsSend.length > 0){
								let ob = this.itemsSend[0];
								this.selected = [ob];
								this.rowClickSend(ob, ob);

								if(this.selected.length > 0) {

									_sLotProdSelPalletSendService.GetDetailQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
										if (resp.status == 200) {
											this.itemsDetail = resp.data;
											this.printQR();
											console.log("item seleccionado", ob);
											this.executeQR = false
											
										}
									});
								}
								
							}
						}
					});
			},
			searchitemsSend(date){
				console.log("date", date);
				this.getPalletSend();
			},
			inputTypeSelectionFrozen(item)
			{
				
			},

			closeModalDialogDiscard()
			{
				this.dialogDiscard = false;
			},

			/* getdata()
			{
				_sLotProdSelPalletService
				.getlotprodsupplypallet({ TypeFrozenCategory: this.TypeFrozenCategory }, this.$fun.getUserID())
				.then(r => {
					this.itemsSupplyPallet = r.data;
                    console.log("🚀 ~ file: FrzSupplyPallet.vue ~ line 373 ~ r.data", r.data)
					if(this.itemsSupplyPallet.length > 0){
						this.typecultivepre = this.itemsSupplyPallet[0].TypeCultive;
                        console.log("🚀 ~ file: FrzSupplyPallet.vue ~ line 375 ~ this.typecultivepre", this.typecultivepre)
					}
				});
			}, */

			intitialize() {

				let obj = this.$fun.getUserInfo();

				console.log("sam obj", obj);

				let filter = {
					PrsID : obj.PrsID,
					TypeCultive : this.TypeCultiveFilter
				}
				console.log('filter ', filter);

				_sLotProdSelPalletService
					.getlotprodselFrozenCatPalletDed(filter ,this.$fun.getUserID())
					.then(r => {
						this.FrozenCategories = r.data;
						console.log('FrozenCategories ', r.data);
					});
				//obtener palets enviados
				//this.getPalletSend();
			},

			openModal(items) {
				this.openDialog = true;
				this.PalletNro = items.TypePallet;
				this.TypeFrozenCategory = items.TypeFrozenCategory;
				this.TypeFrozenCategoryName = items.TypeFrozenCategoryName;

				this.TypeCultive = items.TypeCultive;
				console.log("items levantar modeal",items);
			},

			close(val) {
				this.openDialog = false;
				this.executeQR = false
				this.intitialize();
				//this.TypeCultive = val;//
			},

			addDefectDiscard()
			{
				this.defect.LlpID = this.LlpID; 
				this.defect.SecStatus = 1;
				this.defect.UsrCreateID = this.$fun.getUserID();
				this.defect.UsrUpdateID = this.$fun.getUserID();
                
				_sLotProdSelPalletDefectService
				.save(this.defect, this.$fun.getUserID())
				.then(resp => {
					if(resp => 200)
					{
						this.$fun.alert("Defecto agregado correctamente", "success");
						this.getDefectsByPallet();
						return;
					}
				})
			},

			removeDefectDiscard(item)
			{
				console.log("🚀 ~ file: FrzSupplyPallet.vue ~ line 494 ~ item", item)
				item.SecStatus = 0;
                
				_sLotProdSelPalletDefectService
				.save(item, this.$fun.getUserID())
				.then(resp => {
					if(resp => 200)
					{
						this.$fun.alert("Defecto eliminado correctamente", "success");
						this.getDefectsByPallet();
						return;
					}
				})
			},

			getDefectsByPallet()
			{
				_sLotProdSelPalletDefectService
				.list(this.defect, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsDiscard = resp.data;
                        console.log("🚀 ~ file: FrzSupplyPallet.vue ~ line 517 ~ this.itemsDiscard", this.itemsDiscard)
					}
				})
			},
 
			clickSend(val) {

				console.log('calllll ', val.LpsWeightPalletGross);

				val.LlpQuantityJaba = val.LpsQuantityJaba;
				val.LlpWeight = val.LpsWeightPalletNet;
				val.LlpWeightOriginalNet = val.LpsWeightPalletNet;
				val.LlpWeightOriginalGross = val.LpsWeightPalletGross;

				if(val.TypeFrozenCategory !== 7 && val.TypeFrozenCategory != 10) {
					if (val.TypeSelectionFrozen == null || val.TypeSelectionFrozen == 0) {
						this.$fun.alert("Tipo de Selección", "warning");
						return;
					}
				}

				if(val.TypePalletMate == null || val.TypePalletMate == 0) {
					this.$fun.alert("Elija un tipo de pallet", "warning");
					return;
				}
				
				if (val.LlpQuantityJaba == 0) {
					this.$fun.alert("Pallet no se encuentra abastecido", "warning");
					return;
				}

				let obj = this.$fun.getUserInfo();
				val.PrsID =  obj.PrsID,
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();

				console.log("Enviar pallet", val);

				this.pallet = val;
				val.LlpOriginStage = 1;
				this.$fun
					.alert("Esta de seguro de enviar Pallet ?", "question")
					.then(res => {
						if (res.value) {
							 
							_sLotProdSelPalletSendService
							.send(val, this.$fun.getUserID())
							.then(r => {
								if (r.status == 200) {
									if(val.TypeSelectionFrozen == 3)
									{
										let obj = {
											TypeCultive : val.TypeCultive
										}
										_sDamageFlawCultiveService
										.list(obj, this.$fun.getUserID())
										.then(res => {
											if(res.status == 200)
											{
												this.itemsDefects = res.data;
												this.dialogDiscard = true;
												this.LlpID = r.data.LlpID;
											}
										})
									}
									
										this.$fun.alert(
											"Pallet creado con exito",
											"success",
										);
										this.intitialize();
										this.executeQR = true
									
										//samir
										this.getPalletSend();
								}
							});
						}
					});
			},

			rowClickSend: function(item, row) {
				this.selected = [item];
			
				console.log("item cargados del item", item);
				console.log('items del selected', this.selected);
				

					if(this.selected.length > 0) {

						_sLotProdSelPalletSendService.GetDetailQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
								this.itemsDetail = resp.data;
								console.log("FFFF",this.itemsDetail);
								/* this.WbbNetWeight = 0;
								this.itemsSendDetail.forEach(element => {
									this.WbbNetWeight += element.WbbNetWeight
								}); */
								
							}
						});
					}

			},

			printQR() {
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				//samir LlpID
				 console.log("this.selected[0] qr", this.selected[0])
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' />",
					);
					pwa.document.write("<b style='font-size: 50px; z-index: 2;transform: rotateZ(0deg);margin-top: 40px;position: absolute;width: 133px;border-radius: 20px;'>" + 
				((this.selected[0].TypeSelectionFrozenName === null) ? 'DESCARTE' : this.selected[0].TypeSelectionFrozenName ) + "</b>"); 

					pwa.document.write("</div><div style='margin: 50px 10px 0px 410px;'><table border='1'>");

					pwa.document.write(
						"<tr><td colspan='2' style='text-align:center;'><b>Sunshine</b></td>" +  
								// "<td align='center'><b> " + this.selected[0].TypeCultiveName + "-" + this.selected[0].VrtName +
								// " <br> "  + this.selected[0].TypeCropName +
								// " </b></td>" + 
								"</tr>"+
								 "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
								"<br>F. creación:20/09/2021" +
								"<br>F. última revisión:18/09/2023</td></tr>" +		

							"<tr><td>Materia Prima</td><td>" + this.selected[0].TypeCultiveName + "</td></tr>"+
							"<tr><td>Variedad</td><td>" + this.selected[0].VrtName + "</td></tr>"+
							"<tr><td>Tipo de Cultivo</td><td>" + this.selected[0].TypeCropName + "</td></tr>"+
							"<tr><td>N° Pallet</td><td>" + this.selected[0].LlpCorrelative + "</td></tr>" +
							"<tr><td>Peso Calibrado (Kg)</td><td>" + this.selected[0].LlpWeightOriginalNet + " KG.</td></tr>"+
							"<tr><td>Estado de Madurez</td><td></td></tr>" +
							"<tr><td>Tamaño</td><td>" + this.selected[0].TypeFrozenCategoryName + " </td></tr>" + 
							"<tr><td>Tipo Selección</td><td>" +  	
							((this.selected[0].TypeSelectionFrozenName === null) ? 'DESCARTE ' : this.selected[0].TypeSelectionFrozenName) + "</td></tr>" +
										
							"<tr><td>ID pallet sunexpert</td><td>" + this.selected[0].LlpID + "</td></tr>" +			
							"<tr><td>°Brix de Recepcion</td><td>" + "" + "</td></tr>" +	
							// "<tr><td>Turno</td><td>RECEPCION|MADURACION</td></tr>" +
							"<tr><td>%LMF</td><td></td></tr>" +

							"<tr><td>Hora</td><td>"+ (new Date(this.selected[0].SecCreate).getHours()) + ":"+
								""+ (new Date(this.selected[0].SecCreate).getMinutes()) + "</td></tr>" +
							"<tr><td>TUNNEL-SEMANA-BATCH</td><td></td></tr>" +
							"<tr><td>Turno</td><td></td></tr>" +
							"<tr><td>Fecha a.Proceso</td><td></td></tr>" +
							"<tr><td>Peso a Proceso</td><td></td></tr>" +
							"<tr><td>Tipo de Proceso</td><td></td></tr>" +
							"<tr><td>N° de dias en planta</td><td></td></tr>" +
							"<tr><td>Tipo de Desinfección</td><td>I&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| II&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| III</td></tr>"
							
							
					);
					pwa.document.write("</table></div>");

					this.itemsDetail.forEach(detail => {
							
							pwa.document.write("<div style='margin: 30px 0px 0px 410px;'><table border='1'>");

							pwa.document.write(
								"<tr><td>Lote de Materia Prima: </td><td>" + detail.PrfYuliano+ "</td></tr>" +
								"<tr><td>N° de Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
							);

							pwa.document.write(
								// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								/* "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>" + */
								"<tr><td>Tipo de Proveedor: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								// "<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>" +
								"<tr><td>Fecha Recepción</td><td>" + detail.RcfDateReception + "</td></tr>" +
								"<tr><td>Fecha Calibrado</td><td>" + detail.RcfDateCalibrated + "</td></tr>" 
								//+ "<tr><td>°Brix de Recepcion</td><td>" + detail.PercentBrix + "</td></tr>"	
								
							);
						 
							if (this.selected[0].TypeCultiveName == "PALTO") {
								pwa.document.write(								 
									"<tr><td>% De Materia Seca</td><td>" + detail.DryMaterial + "</td></tr>"  
								);
							}

							pwa.document.write("</table></div>");
						});

				/* }); 
				" || " + "<b>s"+ (new Date(this.selected[0].SecCreate).getHours()) + " : "+
								""+ (new Date(this.selected[0].SecCreate).getMinutes()) + "</b>"+
				*/

				
			
				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},

			/* sendData(val)
			{
				this.TypeCultive = val.TypeCultive;
			}, */

			/* clickTranfer(val) {
				this.itemsTranfer = val;
				this.openTranfer = true;
			}, */

			/* closeTransfer(){
				this.intitialize();
				this.openTranfer = false;
			}, */

			deleteParihuela(val){

				console.log("ELIMINAR", val);
				val.SecStatus = 0;
				val.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert("Esta de seguro de eliminar Pallet ?", "question")
					.then(res => {
						if (res.value) {
							
							_sLotProdSelPalletSendService
								.send(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Pallet elimiado con exito",
											"success",
										);
										this.intitialize();
										
									}
								});
						}
					});
			},


			editPallet(item){

				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();

				// this.itemsEdit

				// console.log('Nuevo tipo', this.itemsEdit.TypeSelectionFrozen)

				// if (itemsEdit.LlpWeightOriginalGross > 0) {
				// 	item.LlpWeightOriginalGross = this.itemsEdit.LlpWeightOriginalGross;
				// 	// item.LlpWeightOriginalNet = 0;
				// }

				// item.TypeSelectionFrozen = this.itemsEdit.TypeSelectionFrozen;
				// item.TypePalletMate = this.itemsEdit.TypePalletMate;
				// item.LlpWeightOriginalGross = item.LlpWeightOriginalGross;

				console.log('datos actualizados', item)
				
				this.$fun.alert("Esta seguro de actualizar el pallet? ", "question" )
				.then(res => {
					if(res.value) {
						_sLotProdSelPalletSendService
								.send(item, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Pallet actualizado correctamente",
											"success",
										);
										this.intitialize();
										this.openDialogEditPallet=false
									}
								});
					}
				})
			},

			ModelupdatePallet(item) {
				// let dataaa = {};
				console.log('datos pasados para editar', item);
				this.itemsEdit = {};
				this.itemsEdit = item;
				this.openDialogEditPallet = true;
				// this.dataEdit = item;
			},

			closeEdit() {
				this.itemsEdit = {};
				this.openDialogEditPallet = false;
			},
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendFrozenParihuela = group => {
					this.intitialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingSendParihuela");
				});
			});

		//	console.log("this.FrozenCategories", this.FrozenCategories);
		},
	};
</script>
